/** @format */

import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const sanitize = function (input) {
  return input.replace(/</g, "&lt;").replace(/>/g, "&gt;");
}

const GoogleApi = ({ activityModal, setActivityModal }) => {

  const [loading, setLoading] = useState(false)

  return (
    <div className="position-relative">
    <GooglePlacesAutocomplete
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      autocompletionRequest={{
        componentRestrictions: {
          country: ['ca', 'us'],
        },
        types: ['(regions)'],
      }}
      
      selectProps={{
        placeholder: 'Enter City Name',
        value: activityModal.location ? {label: activityModal.location, value: activityModal} : undefined,
        components: {
          
          LoadingIndicator: undefined,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          IndicatorSeparator: null,
          DropdownIndicator: null,
        },
        onInputChange: () => {
          if(!activityModal){
          setLoading(true);
          }
        },
        onChange :(e) => {
                  const sanitizedInput = sanitize(e.value.description);
                  setActivityModal({ ...activityModal, location: sanitizedInput })
                  setLoading(false);
         },
        noOptionsMessage: () => null,
        styles: {
       
          // eslint-disable-next-line
          // @ts-ignore

          // eslint-disable-next-line
          // @ts-ignore
          control: (provided, { isFocused }: { isFocused: boolean }, active) => ({
            ...provided,
            'width': '100%',
            'height': '2.375rem',
           
            'font-size':' 0.813rem',
            'font-weight': '400',
            'border': '1px solid #e4e6ef;',
            'borderRadius': '.313rem',
            'boxShadow': 'none',
            'position': 'static',
            '&:hover': {
              cursor: 'text',
            },
          
            ...(isFocused && {
              borderColor: 'blue !important',
              
            }),
          }),
         
          // eslint-disable-next-line
          // @ts-ignore
       
          // eslint-disable-next-line
          // @ts-ignore
          option: (provided, { isFocused }: { isFocused: boolean }) => ({
            ...provided,
            'backgroundColor': '#fff',
            ...(isFocused && {
              background: 'lightblue',
            }),
          }),
        },
      }}
    />
    {loading && (
              <div class="position-absolute t-0 r-0 btn btn-transparent">
                <div
                  class="spinner-border spinner-border-sm text-primary"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
    </div> 
)
};

export default React.memo(GoogleApi);
