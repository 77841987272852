/** @format */

import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import GoogleApi from "../google-api";
import { submitNote } from "../../API/Api";
import ActivityModalOrgNameSwapper from "./org-name-swapper";
import {
  displayCreateLoading,
  displayCreateFailure,
  displayCreateSuccess,
} from "../modal-responses";
import { fetchActivities } from "../../redux/FetchActivities/FetchActivitiesActions";
import { successCreateToaster, failureCreateToaster } from "../modal-toasters";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";

/**
 * @param {function} onHide
 * @param {function} postActivity
 * @param {object} modal
 * @param {boolean} show
 *
 * This component returns the modal that the user interfaces with when trying to create a new activity. The activity will be saved in MongoDB.
 */

const categoryValues = [
  "African American",
  "Asian",
  "Hispanic Or Latino",
  "Native American",
  "Native Hawaiian Or Pacific Islander",
  "LGBTQ",
  "Female",
  "Older/Experienced",
  "Collegiate/Entry Level",
  "Disability",
  "Veteran",
  "Diversity-Other",
];

const interactionValues = [
  "In-Person Meeting",
  "Virtual Meeting",
  "Career Fair",
  "Virtual Fair",
  "Email",
  "Phone Call",
  "Facility Tour",
  "Sponsorship",
  "Other Recruiting Event",
  "Other",
];

export const ActivityModal = ({
  show,
  onHide,
  user,
  inventory,
  fetchActivities,
  openFileUploadModal,
  activityModal,
  setActivityModal,
}) => {
  const formRef = useRef();
  const categorySelect = useRef();
  const interactionSelect = useRef();
  const orgNameSelect = useRef();

  const [selectFromInventory, setSelectFromInventory] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("empty");

  const purify = new DOMPurify();

  useEffect(() => {
    if (activityModal.diversityCategories) {
      window
        .$(categorySelect.current)
        .selectpicker("val", activityModal.diversityCategories);
    }
  }, [activityModal]);

  useEffect(() => {
    if (
      user.user.firstName &&
      user.user.lastName &&
      user.user.company.companyId
    ) {
      let fullName = user.user.firstName + " " + user.user.lastName;
      setActivityModal((state) => ({
        ...state,
        author: fullName,
        company_id: user.user.company.companyId,
      }));
    }
  }, [user.user.firstName, user.user.lastName, user.user.company.companyId]);

  useLayoutEffect(() => {
    if (show) {
      console.log("changing layout of selectfields...");
      window.$(".categories-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
        tickIcon: "fal fa-times-circle",
      });
      window.$(".interaction-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
        tickIcon: "fal fa-times-circle",
      });
      if (inventory.inventoryData && selectFromInventory) {
        window.$(".orgNameSelect").selectpicker({
          dropupAuto: false,
          size: 8,
          styleBase: "form-control",
          style: "",
        });
        window.$(".orgNameSelect").selectpicker("val", "");
      }
    } else {
      setTimeout(() => {
        //This is for the fade effect of the modal
        window.$(".categories-select").selectpicker("destroy");
        window.$(".interaction-select").selectpicker("destroy");
        window.$(".orgNameSelect").selectpicker("destroy");
      }, 150);
    }
  }, [show, inventory.inventoryData, selectFromInventory]);

  const checkSelectValidity = useCallback(() => {
    try {
      categorySelect.current.classList.add("was-validated");

      if (interactionSelect.current) {
        if (
          activityModal.diversityCategories &&
          activityModal.diversityCategories.length > 0
        ) {
          categorySelect.current.setCustomValidity("");
        } else {
          categorySelect.current.setCustomValidity("invalid");
        }
      }

      if (categorySelect.current) {
        if (
          activityModal.interactionCategories &&
          activityModal.interactionCategories.length > 0
        ) {
          interactionSelect.current.setCustomValidity("");
        } else {
          interactionSelect.current.setCustomValidity("invalid");
        }
      }

      if (orgNameSelect.current) {
        if (activityModal.organizationName.length > 0) {
          orgNameSelect.current.setCustomValidity("");
        } else {
          orgNameSelect.current.setCustomValidity("invalid");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    activityModal.diversityCategories,
    activityModal.interactionCategories,
    activityModal.organizationName,
  ]);

  const handleResponse = useCallback((response) => {
    /**
     * ! The Submission statuses were commented out upon request from the PM but can be turned out to have pending states on the post requests.
     */
    if (response.status === 1) {
      displayMsg(successCreateToaster);
      // setSubmissionStatus("success");
    } else if (response.status === 0) {
      displayMsg(failureCreateToaster);
      // setSubmissionStatus("error");
    }
  }, []);

  const displaySubmissionStatus = useCallback(() => {
    if (submissionStatus === "loading") return displayCreateLoading();
    else if (submissionStatus === "success") return displayCreateSuccess();
    else if (submissionStatus === "error") return displayCreateFailure();
    else return;
  }, [submissionStatus]);

  const displayMsg = (customToast) => {
    toast(customToast, {
      position: toast.POSITION.TOP_CENTER,
    });
    // toast(Msg) would also work
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const defaultState = {
        organizationName: "",
        diversityCategories: [],
        interactionCategories: [],
        location: "",
        title: "",
        body: "",
        author: "",
        company_id: null,
      };
      formRef.current.classList.add("was-validated");
      checkSelectValidity();
      if (formRef.current.checkValidity()) {
        // setSubmissionStatus("loading");
        const submittedActivity = await submitNote({
          note: activityModal,
          token: user.user.jtToken,
          company_id: user.user.company.companyId,
        });
        handleResponse(submittedActivity);
        setTimeout(() => {
          // setSubmissionStatus("empty");
          fetchActivities(user.user.company.companyId, user.user.jtToken);
          onHide();
        }, 1000);
        setTimeout(() => {
          const fullName = user.user.firstName + " " + user.user.lastName;
          // Dump modal and refetch the user's activities
          setActivityModal({
            ...defaultState,
            author: fullName,
            company_id: user.user.company.companyId,
          });
        }, 2000);
      }
    },
    [
      activityModal,
      checkSelectValidity,
      handleResponse,
      onHide,
      fetchActivities,
      setActivityModal,
      user.user,
    ]
  );

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
      }}
      size="md"
      top="20px"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header className="bg-white">
        <Modal.Title id="contained-modal-title-vcenter">
          Add Activity
        </Modal.Title>
        <button
          type="button"
          className="close opacity-10"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onHide}
        >
          <span aria-hidden="true">
            <i class="fal fa-times text-15 text-dark-fill"></i>
          </span>
        </button>
      </Modal.Header>

      <Form
        className=""
        noValidate
        ref={formRef}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Modal.Body>
          {displaySubmissionStatus()}

          <ActivityModalOrgNameSwapper
            selectFromInventory={selectFromInventory}
            setSelectFromInventory={setSelectFromInventory}
            activityModal={activityModal}
            setActivityModal={setActivityModal}
            orgNameSelect={orgNameSelect}
            inventory={inventory}
          />
          <Form.Group className="mb-3">
            <Form.Label>
              Diversity Categories<span className="text-danger">*</span>
            </Form.Label>
            <select
              title="Select Diversity Categories"
              ref={categorySelect}
              className="categories-select form-control bootstrap-select selectpicker"
              name="categories"
              multiple
              required
              onChange={() => {
                setActivityModal({
                  ...activityModal,
                  diversityCategories: window
                    .$(categorySelect.current)
                    .selectpicker("val"),
                });
              }}
            >
              {categoryValues.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <p className="valid-feedback">Looks good!</p>
            <p className="invalid-feedback">
              Diversity category is required is required
            </p>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Interaction Type<span className="text-danger">*</span>
            </Form.Label>
            <select
              title="Select Interactions"
              className=" interaction-select form-control bootstrap-select selectpicker"
              ref={interactionSelect}
              multiple
              required
              name="interaction"
              onChange={() => {
                setActivityModal({
                  ...activityModal,
                  interactionCategories: window
                    .$(interactionSelect.current)
                    .selectpicker("val"),
                });
              }}
            >
              {interactionValues.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {/* <p className="valid-feedback">Looks good!</p> */}
            <p className="invalid-feedback">Interaction type is required</p>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Location<span></span>
            </Form.Label>
          <GoogleApi
            activityModal={activityModal}
            setActivityModal={setActivityModal}
          />
            </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Number of Referrals</Form.Label>
            <Form.Control
              value={activityModal.numberOfReferals}
              type="number"
              min={0}
              onChange={(e) => {
                setActivityModal((state) => ({
                  ...state,
                  numberOfReferals: e.target.value,
                }));
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Number of Hires</Form.Label>
            <Form.Control
              value={activityModal.numberOfHires}
              type="number"
              min={0}
              onChange={(e) =>
                setActivityModal((state) => ({
                  ...state,
                  numberOfHires: e.target.value,
                }))
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Effectiveness of the Outreach</Form.Label>
            <Form.Control
              value={activityModal.effectiveness}
              placeholder="Describe the effectiveness of the outreach"
              as="textarea"
              type="text"
              onChange={(e) => {
                const sanitizedInput = purify.sanitize(e.target.value);

                setActivityModal((state) => ({
                  ...state,
                  effectiveness: sanitizedInput,
                }));
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Activity Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={activityModal.title}
              onChange={(e) => {
                const sanitizedInput = purify.sanitize(e.target.value);

                setActivityModal({
                  ...activityModal,
                  title: sanitizedInput,
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Activity Details</Form.Label>
            <Form.Control
              name="body"
              as="textarea"
              rows={4}
              value={activityModal.body}
              onChange={(e) => {
                const sanitizedInput = purify.sanitize(e.target.value);

                setActivityModal({
                  ...activityModal,
                  body: sanitizedInput,
                });
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button onClick={openFileUploadModal}>Upload Notes</Button>
          <div>
            <Button
              className="btn mx-2"
              style={{
                backgroundColor: "white",
                color: "#599EE4",
                border: "none",
              }}
              type="button"
              onClick={onHide}
            >
              Cancel
            </Button>
            <Button type="submit" className="submit__button">
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    inventory: state.userInventory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivities: (company_id, token) =>
      dispatch(fetchActivities(company_id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityModal);
